/// <reference types="./navbar.d.mts" />
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $model from "../../../data/model.mjs";
import * as $router from "../../../frontend/router.mjs";
import * as $s from "../../../frontend/view/navbar/styles.mjs";
import { toList, isEqual } from "../../../gleam.mjs";

function navbar_links() {
  return $s.nav_links(
    toList([]),
    toList([
      $s.nav_link(
        toList([$a.href("/analytics")]),
        toList([$h.text("Analytics")]),
      ),
    ]),
  );
}

export function navbar(model) {
  let transparent = isEqual(model.route, new $router.Home());
  return $s.navbar(
    transparent,
    toList([$a.class$("navbar")]),
    toList([
      (() => {
        let $ = model.route;
        if ($ instanceof $router.Home) {
          return navbar_links();
        } else if ($ instanceof $router.Search) {
          return $s.navbar_search(
            toList([]),
            toList([
              $s.navbar_search_title(
                toList([$a.href("/")]),
                toList([
                  $s.search_lucy(40, toList([$a.src("/images/lucy.svg")])),
                  $s.title(toList([]), toList([$h.text("Gloogle")])),
                ]),
              ),
            ]),
          );
        } else if ($ instanceof $router.Trending) {
          return $s.navbar_search(
            toList([]),
            toList([
              $s.navbar_search_title(
                toList([$a.href("/")]),
                toList([
                  $s.search_lucy(40, toList([$a.src("/images/lucy.svg")])),
                  $s.title(toList([]), toList([$h.text("Gloogle")])),
                ]),
              ),
            ]),
          );
        } else if ($ instanceof $router.Analytics) {
          return $s.navbar_search(
            toList([]),
            toList([
              $s.navbar_search_title(
                toList([$a.href("/")]),
                toList([
                  $s.search_lucy(40, toList([$a.src("/images/lucy.svg")])),
                  $s.title(toList([]), toList([$h.text("Gloogle")])),
                ]),
              ),
            ]),
          );
        } else {
          return $s.navbar_search(
            toList([]),
            toList([
              $s.navbar_search_title(
                toList([$a.href("/")]),
                toList([
                  $s.search_lucy(40, toList([$a.src("/images/lucy.svg")])),
                  $s.title(toList([]), toList([$h.text("Gloogle")])),
                ]),
              ),
            ]),
          );
        }
      })(),
    ]),
  );
}

/// <reference types="./msg.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $http from "../../lustre_http/lustre_http.mjs";
import * as $event from "../../plinth/plinth/browser/event.mjs";
import * as $package from "../data/package.mjs";
import * as $search_result from "../data/search_result.mjs";
import * as $router from "../frontend/router.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class Functions extends $CustomType {}

export class Types extends $CustomType {}

export class Aliases extends $CustomType {}

export class Documented extends $CustomType {}

export class ShowOldPackages extends $CustomType {}

export class DocumentationSearch extends $CustomType {}

export class VectorSearch extends $CustomType {}

export class Package extends $CustomType {
  constructor(name, repository, rank, popularity) {
    super();
    this.name = name;
    this.repository = repository;
    this.rank = rank;
    this.popularity = popularity;
  }
}

export class Analytics extends $CustomType {
  constructor(total_searches, total_signatures, total_indexed, timeseries, ranked, popular) {
    super();
    this.total_searches = total_searches;
    this.total_signatures = total_signatures;
    this.total_indexed = total_indexed;
    this.timeseries = timeseries;
    this.ranked = ranked;
    this.popular = popular;
  }
}

export class None extends $CustomType {}

export class Packages extends $CustomType {
  constructor(packages) {
    super();
    this.packages = packages;
  }
}

export class OnSearchFocus extends $CustomType {
  constructor(event) {
    super();
    this.event = event;
  }
}

export class SubmitSearch extends $CustomType {}

export class UpdateIsMobile extends $CustomType {
  constructor(is_mobile) {
    super();
    this.is_mobile = is_mobile;
  }
}

export class SearchResults extends $CustomType {
  constructor(input, result) {
    super();
    this.input = input;
    this.result = result;
  }
}

export class Trendings extends $CustomType {
  constructor(result) {
    super();
    this.result = result;
  }
}

export class UpdateInput extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Reset extends $CustomType {}

export class ScrollTo extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnEscape extends $CustomType {}

export class OnAnalytics extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnRouteChange extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class OnCheckFilter extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

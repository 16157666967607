/// <reference types="./model.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $regex from "../../gleam_stdlib/gleam/regex.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $element from "../../lustre/lustre/element.mjs";
import * as $kind from "../data/kind.mjs";
import * as $msg from "../data/msg.mjs";
import * as $package from "../data/package.mjs";
import * as $search_result from "../data/search_result.mjs";
import * as $router from "../frontend/router.mjs";
import * as $cache from "../frontend/view/body/cache.mjs";
import { toList, CustomType as $CustomType, makeError, isEqual } from "../gleam.mjs";
import { isMobile as is_mobile } from "../gloogle.ffi.mjs";

export class Model extends $CustomType {
  constructor(input, search_results, index, loading, packages, view_cache, route, is_mobile, trendings, submitted_input, keep_functions, keep_types, keep_aliases, keep_documented, show_old_packages, show_documentation_search, show_vector_search, total_searches, total_signatures, total_packages, timeseries, ranked, popular) {
    super();
    this.input = input;
    this.search_results = search_results;
    this.index = index;
    this.loading = loading;
    this.packages = packages;
    this.view_cache = view_cache;
    this.route = route;
    this.is_mobile = is_mobile;
    this.trendings = trendings;
    this.submitted_input = submitted_input;
    this.keep_functions = keep_functions;
    this.keep_types = keep_types;
    this.keep_aliases = keep_aliases;
    this.keep_documented = keep_documented;
    this.show_old_packages = show_old_packages;
    this.show_documentation_search = show_documentation_search;
    this.show_vector_search = show_vector_search;
    this.total_searches = total_searches;
    this.total_signatures = total_signatures;
    this.total_packages = total_packages;
    this.timeseries = timeseries;
    this.ranked = ranked;
    this.popular = popular;
  }
}

export function update_route(model, route) {
  return model.withFields({ route: route });
}

export function update_submitted_input(model) {
  return model.withFields({ submitted_input: model.input });
}

export function update_is_mobile(model, is_mobile) {
  return model.withFields({ is_mobile: is_mobile });
}

export function update_trendings(model, trendings) {
  let _pipe = model.trendings;
  let _pipe$1 = $option.unwrap(_pipe, toList([]));
  let _pipe$2 = $list.append(_pipe$1, trendings);
  let _pipe$3 = new $option.Some(_pipe$2);
  return ((t) => { return model.withFields({ trendings: t }); })(_pipe$3);
}

export function toggle_loading(model) {
  return model.withFields({ loading: !model.loading });
}

export function update_input(model, content) {
  return model.withFields({ input: content });
}

export function update_analytics(model, analytics) {
  return model.withFields({
    timeseries: analytics.timeseries,
    total_searches: analytics.total_searches,
    total_signatures: analytics.total_signatures,
    total_packages: analytics.total_indexed,
    ranked: analytics.ranked,
    popular: analytics.popular
  });
}

export function search_key(key, model) {
  return key + $string.inspect(
    toList([
      model.keep_functions,
      model.keep_types,
      model.keep_aliases,
      model.keep_documented,
      model.show_old_packages,
      model.show_documentation_search,
      model.show_vector_search,
    ]),
  );
}

function default_search_key(key) {
  return key + $string.inspect(
    toList([false, false, false, false, true, true, true]),
  );
}

function is_higher(loop$new, loop$old) {
  while (true) {
    let new$ = loop$new;
    let old = loop$old;
    let $ = $list.first(new$);
    let $1 = $list.first(old);
    if (!$.isOk() && !$1.isOk()) {
      return true;
    } else if ($.isOk() && !$1.isOk()) {
      return true;
    } else if (!$.isOk() && $1.isOk()) {
      return false;
    } else {
      let part1 = $[0];
      let part2 = $1[0];
      let $2 = part1 === part2;
      if (!$2) {
        return part1 > part2;
      } else {
        loop$new = (() => {
          let _pipe = $list.rest(new$);
          return $result.unwrap(_pipe, toList([]));
        })();
        loop$old = (() => {
          let _pipe = $list.rest(old);
          return $result.unwrap(_pipe, toList([]));
        })();
      }
    }
  }
}

function extract_package_version(acc, search_result) {
  let $ = $regex.from_string("^[0-9]*.[0-9]*.[0-9]*$");
  if (!$.isOk()) {
    throw makeError(
      "assignment_no_match",
      "data/model",
      192,
      "extract_package_version",
      "Assignment pattern did not match",
      { value: $ }
    )
  }
  let re = $[0];
  let $1 = $regex.check(re, search_result.version);
  if (!$1) {
    return acc;
  } else {
    let $2 = $dict.get(acc, search_result.package_name);
    if (!$2.isOk()) {
      return $dict.insert(
        acc,
        search_result.package_name,
        search_result.version,
      );
    } else {
      let content = $2[0];
      let old = (() => {
        let _pipe = $string.split(content, ".");
        let _pipe$1 = $list.map(_pipe, $int.parse);
        return $list.map(
          _pipe$1,
          (_capture) => { return $result.unwrap(_capture, 0); },
        );
      })();
      let new$ = (() => {
        let _pipe = $string.split(search_result.version, ".");
        let _pipe$1 = $list.map(_pipe, $int.parse);
        return $list.map(
          _pipe$1,
          (_capture) => { return $result.unwrap(_capture, 0); },
        );
      })();
      let $3 = (() => {
        let _pipe = new$;
        return is_higher(_pipe, old);
      })();
      if ($3) {
        return $dict.insert(
          acc,
          search_result.package_name,
          search_result.version,
        );
      } else {
        return acc;
      }
    }
  }
}

export function reset(model) {
  return new Model(
    "",
    model.search_results,
    toList([]),
    false,
    model.packages,
    model.view_cache,
    new $router.Home(),
    is_mobile(),
    model.trendings,
    "",
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    model.total_searches,
    model.total_signatures,
    model.total_packages,
    model.timeseries,
    model.ranked,
    model.popular,
  );
}

function insert_module_names(index, search_results) {
  return $list.fold(
    search_results,
    index,
    (acc, val) => {
      let key = [val.package_name, val.version];
      let _pipe = $list.key_find(acc, key);
      let _pipe$1 = $result.unwrap(_pipe, toList([]));
      let _pipe$2 = ((i) => {
        return $list.prepend(i, [val.module_name, val.name]);
      })(_pipe$1);
      return ((i) => { return $list.key_set(acc, key, i); })(_pipe$2);
    },
  );
}

function compute_index(search_results) {
  if (search_results instanceof $search_result.Start) {
    return toList([]);
  } else if (search_results instanceof $search_result.InternalServerError) {
    return toList([]);
  } else {
    let types = search_results.exact_type_matches;
    let exact = search_results.exact_name_matches;
    let others = search_results.name_signature_matches;
    let searches = search_results.vector_signature_searches;
    let docs = search_results.docs_searches;
    let modules = search_results.module_searches;
    let _pipe = toList([]);
    let _pipe$1 = insert_module_names(_pipe, types);
    let _pipe$2 = insert_module_names(_pipe$1, exact);
    let _pipe$3 = insert_module_names(_pipe$2, others);
    let _pipe$4 = insert_module_names(_pipe$3, searches);
    let _pipe$5 = insert_module_names(_pipe$4, docs);
    let _pipe$6 = insert_module_names(_pipe$5, modules);
    return $list.map(
      _pipe$6,
      (i) => { return $pair.map_second(i, $list.reverse); },
    );
  }
}

export function init() {
  let search_results = new $search_result.Start();
  let index = compute_index(search_results);
  return new Model(
    "",
    $dict.new$(),
    index,
    false,
    toList([]),
    $dict.new$(),
    new $router.Home(),
    is_mobile(),
    new $option.None(),
    "",
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    0,
    0,
    0,
    toList([]),
    toList([]),
    toList([]),
  );
}

export function update_search_results_filter(model) {
  let default_key = default_search_key(model.submitted_input);
  let show_old = (() => {
    let $ = model.show_old_packages;
    if ($) {
      return (_) => { return true; };
    } else {
      let last_versions = (() => {
        let $1 = $dict.get(model.search_results, default_key);
        if (!$1.isOk()) {
          return $dict.new$();
        } else {
          let search_results = $1[0];
          if (search_results instanceof $search_result.Start) {
            return $dict.new$();
          } else if (search_results instanceof $search_result.InternalServerError) {
            return $dict.new$();
          } else {
            let t = search_results.exact_type_matches;
            let e = search_results.exact_name_matches;
            let m = search_results.name_signature_matches;
            let s = search_results.vector_signature_searches;
            let d = search_results.docs_searches;
            let mods = search_results.module_searches;
            let _pipe = $dict.new$();
            let _pipe$1 = ((_capture) => {
              return $list.fold(t, _capture, extract_package_version);
            })(_pipe);
            let _pipe$2 = ((_capture) => {
              return $list.fold(e, _capture, extract_package_version);
            })(_pipe$1);
            let _pipe$3 = ((_capture) => {
              return $list.fold(m, _capture, extract_package_version);
            })(_pipe$2);
            let _pipe$4 = ((_capture) => {
              return $list.fold(s, _capture, extract_package_version);
            })(_pipe$3);
            let _pipe$5 = ((_capture) => {
              return $list.fold(d, _capture, extract_package_version);
            })(_pipe$4);
            return ((_capture) => {
              return $list.fold(mods, _capture, extract_package_version);
            })(_pipe$5);
          }
        }
      })();
      return (a) => {
        let $1 = $dict.get(last_versions, a.package_name);
        if (!$1.isOk()) {
          return false;
        } else {
          let content = $1[0];
          return content === a.version;
        }
      };
    }
  })();
  let or_filters = (() => {
    let _pipe = toList([
      [
        model.keep_functions,
        (s) => { return isEqual(s.kind, new $kind.Function()); },
      ],
      [
        model.keep_types,
        (s) => { return isEqual(s.kind, new $kind.TypeDefinition()); },
      ],
      [
        model.keep_aliases,
        (s) => { return isEqual(s.kind, new $kind.TypeAlias()); },
      ],
    ]);
    let _pipe$1 = $list.filter(_pipe, (a) => { return a[0]; });
    return $list.map(_pipe$1, $pair.second);
  })();
  let and_filters = (() => {
    let _pipe = toList([
      [
        model.keep_documented,
        (s) => { return $string.length(s.documentation) > 0; },
      ],
    ]);
    let _pipe$1 = $list.filter(_pipe, (a) => { return a[0]; });
    return $list.map(_pipe$1, $pair.second);
  })();
  let filter = (s) => {
    return ((() => {
      let $ = $list.is_empty(or_filters);
      if ($) {
        return true;
      } else {
        return $list.any(
          or_filters,
          (_capture) => { return $function.apply1(_capture, s); },
        );
      }
    })() && (() => {
      let $ = $list.is_empty(and_filters);
      if ($) {
        return true;
      } else {
        return $list.any(
          and_filters,
          (_capture) => { return $function.apply1(_capture, s); },
        );
      }
    })()) && show_old(s);
  };
  let key = search_key(model.submitted_input, model);
  let $ = $dict.get(model.search_results, default_key);
  if (!$.isOk()) {
    return model;
  } else {
    let search_results = $[0];
    let search_results$1 = (() => {
      if (search_results instanceof $search_result.Start) {
        return search_results;
      } else if (search_results instanceof $search_result.InternalServerError) {
        return search_results;
      } else {
        let t = search_results.exact_type_matches;
        let e = search_results.exact_name_matches;
        let m = search_results.name_signature_matches;
        let s = search_results.vector_signature_searches;
        let d = search_results.docs_searches;
        let mods = search_results.module_searches;
        return new $search_result.SearchResults(
          (() => {
            let _pipe = t;
            return $list.filter(_pipe, filter);
          })(),
          (() => {
            let _pipe = e;
            return $list.filter(_pipe, filter);
          })(),
          (() => {
            let _pipe = m;
            return $list.filter(_pipe, filter);
          })(),
          (() => {
            let $1 = model.show_vector_search;
            if (!$1) {
              return toList([]);
            } else {
              let _pipe = s;
              return $list.filter(_pipe, filter);
            }
          })(),
          (() => {
            let $1 = model.show_documentation_search;
            if (!$1) {
              return toList([]);
            } else {
              let _pipe = d;
              return $list.filter(_pipe, filter);
            }
          })(),
          (() => {
            let _pipe = mods;
            return $list.filter(_pipe, filter);
          })(),
        );
      }
    })();
    let index = compute_index(search_results$1);
    let view_cache = (() => {
      if (search_results$1 instanceof $search_result.Start) {
        return model.view_cache;
      } else if (search_results$1 instanceof $search_result.InternalServerError) {
        return model.view_cache;
      } else {
        let types = search_results$1.exact_type_matches;
        let e = search_results$1.exact_name_matches;
        let m = search_results$1.name_signature_matches;
        let s = search_results$1.vector_signature_searches;
        let d = search_results$1.docs_searches;
        let mods = search_results$1.module_searches;
        let _pipe = $cache.cache_search_results(
          model.submitted_input,
          index,
          types,
          e,
          m,
          s,
          d,
          mods,
        );
        return ((_capture) => {
          return $dict.insert(model.view_cache, key, _capture);
        })(_pipe);
      }
    })();
    return model.withFields({
      search_results: $dict.insert(model.search_results, key, search_results$1),
      index: index,
      view_cache: view_cache
    });
  }
}

export function update_search_results(model, key, search_results) {
  let key$1 = default_search_key(key);
  let index = compute_index(search_results);
  let view_cache = (() => {
    if (search_results instanceof $search_result.Start) {
      return model.view_cache;
    } else if (search_results instanceof $search_result.InternalServerError) {
      return model.view_cache;
    } else {
      let types = search_results.exact_type_matches;
      let e = search_results.exact_name_matches;
      let m = search_results.name_signature_matches;
      let s = search_results.vector_signature_searches;
      let d = search_results.docs_searches;
      let mods = search_results.module_searches;
      let _pipe = $cache.cache_search_results(
        model.submitted_input,
        index,
        types,
        e,
        m,
        s,
        d,
        mods,
      );
      return ((_capture) => {
        return $dict.insert(model.view_cache, key$1, _capture);
      })(_pipe);
    }
  })();
  let _pipe = model.withFields({
    search_results: $dict.insert(model.search_results, key$1, search_results),
    index: index,
    view_cache: view_cache
  });
  return update_search_results_filter(_pipe);
}
